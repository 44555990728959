import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PricingViewComponent } from '../pricing-view/pricing-view.component';
import { PricingPeriod } from '../pricing';
import { SUBSCRIPTION_REASON } from '../../generate/generate';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { PaddleService } from '../../payments/paddle.service';
import { PixelsService } from '../../shared/pixels.service';

@Component({
  selector: 'app-pricing-dialog',
  imports: [PricingViewComponent, MatButtonModule, CommonModule],
  templateUrl: './pricing-dialog.component.html',
  styleUrl: './pricing-dialog.component.scss'
})
export class PricingDialogComponent implements OnDestroy {
  public SUBSCRIPTION_REASON = SUBSCRIPTION_REASON;
  public MONTHLY = PricingPeriod.MONTHLY;
  public YEARLY = PricingPeriod.YEARLY;
  public state = PricingPeriod.MONTHLY;
  public ready: boolean;
  constructor(
    public dialogRef: MatDialogRef<PricingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: SUBSCRIPTION_REASON,
    private paddleService: PaddleService,
    private pixels: PixelsService,
  ) {
    document.body.classList.add('pricing-dialog');
    this.init();
  }

  async init() {
    this.pixels.sendPixel({
      event: 'click',
      // button_name: 'generate preview image',
      click_type: 'pricing_dialog',
      action: this.context
    });
    await this.paddleService.getAllCyclicalPackages();
    this.ready = true;
  }

  ngOnDestroy() {
    document.body.classList.remove('pricing-dialog');
  }

  close() {
    this.dialogRef.close();
  }
}
