import { Injectable } from '@angular/core';
import { Role, RolesConfig, RolesManagerService } from 'ng-role-based-access-control';
import { StorageService } from 'ng-storage-service';
import { Subject, Subscription } from 'rxjs';
import { RestService } from '../communication/rest.service';
import { AuthService } from './auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { User, UserRole } from './user';

@Injectable({
  providedIn: 'root'
})
export class RolesHelperService {
  private fetchingRoles: boolean;
  public onRolesFetched: Subject<null>;
  private init: boolean;
  private subs: Array<Subscription>;
  private _cache: { [id: string]: boolean };
  private _userRoles: { [id: string]: boolean };
  constructor(
    private roles: RolesManagerService,
    private storage: StorageService,
    private rest: RestService,
    private auth: AuthService,
    private broadcaster: BroadcasterService
  ) {
    this.subs = [];
    this.fetchingRoles = false;
    this.onRolesFetched = new Subject<null>();
    this._userRoles = {};
    this._cache = {};
    this.subs.push(this.broadcaster.on('onLogout').subscribe(this.deleteLazy.bind(this)));
    this.subs.push(this.broadcaster.on('onLogin').subscribe(this.deleteLazy.bind(this)));
  }

  deleteLazy() {
    this._cache = {};
    this.deleteRolesCache();
  }

  deleteRolesCache() {
    this._userRoles = {};
  }

  // initialize role config from server
  fetchRoles() {
    try {
      let last = this.storage.get('roles'), isUpdatedFormat = true;
      if (last) {
        if (last.roles_permissions instanceof Array && (!last.roles_permissions[0].role_name || !last.roles_permissions[0].permission_description)) {
          isUpdatedFormat = false;
        }
        if (isUpdatedFormat) {
          this.roles.setConfig(last);
          this.init = true;
          this.deleteRolesCache();
          this.onRolesFetched.next(null);
        }
      }
    }
    catch (e) { }
    if (!this.fetchingRoles) {
      this.fetchingRoles = true;
      this.rest.rolesConfig('GET').subscribe((data: RolesConfig) => {
        this.fetchingRoles = false;
        this.roles.setConfig(data);
        this.storage.set('roles', data);
        this.init = true;
        this.deleteRolesCache();
        this.onRolesFetched.next(null);
      },
        err => {
          this.fetchingRoles = false;
          if (!this.roles.getConfig()) {
            this.init = false;
            // return this.auth.logout();
            return;
          }
          // this.init = true;
          this.onRolesFetched.next(null);
        });
    }
  }

  getAllUIRoles(): Array<Role> {
    if (this.init) {
      let res = [] as Array<Role>;
      this.roles.getConfig().roles.forEach(role => {
        res.push(
          {
            created_at: role.created_at,
            id: role.id,
            name: role.name,
            updated_at: role.updated_at
          }
        );
      });
      return res;
    }
    return null;
  }

  getUIRoles(existing?: Array<UserRole>): Array<Role> {
    if (this.init) {
      let res = [] as Array<Role>;
      existing = existing ? existing : [];
      this.roles.getConfig().roles.forEach(role => {
        if (existing.filter(r => r.role_id == role.id).length > 0)
          res.push(
            {
              created_at: role.created_at,
              id: role.id,
              name: role.name,
              updated_at: role.updated_at
            }
          );
      });
      return res;
    }
    return null;
  }

  getRoleIdbyName(name: string) {
    let roles = this.getAllUIRoles();
    for (let i = 0; i < roles?.length; i++) {
      if (roles[i].name === name) {
        return roles[i].id;
      }
    }
    return null;
  }

  save(config: RolesConfig): void {
    this.rest.rolesConfig('PUT', config).subscribe((data: RolesConfig) => {
      this.fetchRoles();
    });
  }

  async doesUserHasPermission(permissionName: string, user?: User): Promise<boolean> {
    if (!await this.auth.isloggedIn()) return false;
    user = this.auth.user;
    if (!user.roles)
      user = this.auth.atachRolesToUser(user);
    if (!user.roles) return false;
    if (typeof this._userRoles[permissionName] !== 'boolean')
      this._userRoles[permissionName] = this.roles.doesRolesHasPermission(user.roles, permissionName);
    return this._userRoles[permissionName];
  }

  isInit() {
    return this.init;
  }

  isRoleLogedin(roleName: string, user?: User): boolean {
    user = user || this.auth.user;
    if (!user)
      return false;
    if (!user.roles)
      user = this.auth.atachRolesToUser(user);
    if (typeof this._cache[roleName] !== 'boolean')
      this._cache[roleName] = user && this.roles.isUserInRole(user.roles, roleName);
    return this._cache[roleName];
  }
}
