import { Injectable } from '@angular/core';
import EventBus from '@vertx/eventbus-bridge-client.js';
import { StorageService } from 'ng-storage-service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { ServiceWorkerService } from './service-worker.service';
import { RolesManagerService } from 'ng-role-based-access-control';
import { RolesHelperService } from '../auth/roles-helper.service';
import { HEXA_PLATFORM, IPlaygroundNotification, IPlaygroundNotificationResponse } from '../shared/enums';
import { User } from '../auth/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsWebsocketService {
  // For sending to server:
  static PREFIX = 'com.hexa.notifications.server.'
  // For listening to server:
  static PREFIX_CLIENT = 'com.hexa.notifications.client.'
  private eventBus: any;
  private onLogin: Subscription;
  private onLogout: Subscription;
  private connetionLossCount: number;
  private connetionLossNotify: boolean;
  private onRolesFetched: Subscription;
  public onStateChange: Subject<boolean>;
  public onAnnouncement: Subject<IPlaygroundNotification>;
  public onPublish: Subject<IPlaygroundNotification>;
  public onAnnouncements: Subject<IPlaygroundNotificationResponse>;
  public onRead: Subject<number>;
  public onReadAll: Subject<null>;
  public isOpen: boolean;
  public _isOpening: boolean;
  constructor(
    private storage: StorageService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    // private textToSpeech: TextToSpeechService,
    private swService: ServiceWorkerService,
    private roles: RolesManagerService,
    private rolesHelper: RolesHelperService) {
    this.isOpen = false;
    this._isOpening = false;
    this.onAnnouncement = new Subject<IPlaygroundNotification>();
    this.onAnnouncements = new Subject<IPlaygroundNotificationResponse>();
    this.onPublish = new Subject<IPlaygroundNotification>();
    this.onRead = new Subject<number>();
    this.onReadAll = new Subject<null>();
    this.connetionLossCount = 0;
    this.connetionLossNotify = false;
    if (this.auth.isloggedIn())
      this.connect();
    this.onLogin = this.broadcaster.on<User>('onLogin').subscribe(
      user => this.connect()
    );
    this.onLogout = this.broadcaster.on<User>('onLogout').subscribe(
      user => this.disconnect()
    );
    this.onStateChange = new Subject<boolean>();
    this.onRolesFetched = this.rolesHelper.onRolesFetched.subscribe(
      data => {
        this.auth.refreshUserDate();
      }
    );
  }

  public fetch(limit: number, offset: number, types: Array<number>) {
    if (!this.auth.isloggedIn() || !this.isOpen) return;
    const obj = {
      limit,
      offset,
      types,
      platform_id: HEXA_PLATFORM.GEN_AI
    }
    this.eventBus.send(`${NotificationsWebsocketService.PREFIX}fetch`, obj, {}, (err: any, response: any) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onAnnouncements.next(response.body);
    });
  }

  public read(notificationId: number) {
    let obj = {
      id: notificationId
    }
    this.eventBus.send(`${NotificationsWebsocketService.PREFIX}read`, obj, {}, (err: any, response: any) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onRead.next(response.body instanceof Array ? response.body[0] : response.body);
    });
  }

  public readAll() {
    this.eventBus.send(`${NotificationsWebsocketService.PREFIX}mark.all.read`, {}, {}, (err: any, response: any) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else
        this.onReadAll.next(response.body instanceof Array ? response.body[0] : response.body);
    });
  }

  private connect() {
    if (this.isOpen || this._isOpening) return;
    this._isOpening = true;
    const token = this.storage.get('token');
    if (token) {
      const options = {
        vertxbus_reconnect_attempts_max: Infinity,
        vertxbus_reconnect_delay_min: 1000,
        vertxbus_reconnect_delay_max: 5000,
        vertxbus_reconnect_exponent: 2,
        vertxbus_randomization_factor: 0.5
      };
      this.eventBus = new EventBus(this.auth.notificationsWebsocketEndpoint + '/cmseventbus?token=' + token, options);
      this.eventBus.onopen = this.onopen.bind(this);
      this.eventBus.onclose = this.onclose.bind(this);
      this.eventBus.enableReconnect(true);
    }
    else
      this._isOpening = false;
  }

  private onopen() {
    this._isOpening = false;
    this.setState(true);
    this.register();
    this.broadcaster.broadcast('onWebsocketOpen');
  }

  private onclose() {
    this.setState(false);
  }

  private setState(state: boolean) {
    this.isOpen = state;
    console.log('Notifications WebSocket is ' + (this.isOpen ? 'open' : 'close'));
    if (this.isOpen) {
      this.rolesHelper.fetchRoles();
      this.connetionLossCount = 0;
      if (environment.production)
        this.swService.checkForUpdate(); // after machine sleep or offline check for updates
      // if (this.connetionLossNotify) {

      // }
      this.connetionLossNotify = false;
    }
    else {
      if (++this.connetionLossCount > 10 && !this.connetionLossNotify) {
        this.connetionLossNotify = true;
      }
    }
    this.onStateChange.next(this.isOpen);
  }

  private register() {
    this.eventBus.registerHandler(`${NotificationsWebsocketService.PREFIX_CLIENT}${this.auth.user.id}`, (err: any, response: any) => {
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else {
        this.onAnnouncement.next(response.body);
        this.broadcaster.broadcast('onAnnouncement', response.body);
      }
    });

    this.eventBus.registerHandler(`${NotificationsWebsocketService.PREFIX_CLIENT}${this.auth.user.id}.publish`, (err: any, response: any) => {
      const name = 'onPublish';
      console.log(name, response);
      if (err || response.body.code == 500)
        console.warn(response.body.error);
      else {
        this.onPublish.next(response.body);
        this.broadcaster.broadcast(name, response.body);
      }
    });
  }

  private disconnect() {
    if (this.eventBus && this.eventBus.state != this.eventBus.CLOSING && this.eventBus.state != this.eventBus.CLOSED)
      this.eventBus.close();
  }
}
