import { Injectable } from '@angular/core';
import {
  Environments,
  Paddle,
  PricePreviewItem,
  PricePreviewResponse,
  initializePaddle,
} from '@paddle/paddle-js';
import { environment } from '../../environments/environment';
import { RestService } from '../communication/rest.service';
import { UtilsService } from '../shared/utils.service';
import {
  IPaddleEventCallback,
  IPaymentPackage,
  IPaymentPackagePrice,
  IPricingCardDetails,
  PricingPeriod,
} from '../pricing/pricing';
import { AuthService } from '../auth/auth.service';
import { PixelsService } from '../shared/pixels.service';
import { IUserProfile } from '../auth/user';

@Injectable({
  providedIn: 'root',
})
export class PaddleService {
  private _paddleInstance: Paddle;
  private _getPackagesStock: Array<any>;
  private _allPrices: Array<IPaymentPackage>;
  private _onCheckout: Function;
  constructor(
    private rest: RestService,
    private utils: UtilsService,
    private auth: AuthService,
    private pixels: PixelsService
  ) {
    this._getPackagesStock = [];
  }

  get paddle() {
    return this._paddleInstance;
  }

  private set paddle(value: Paddle) {
    this._paddleInstance = value;
  }

  private init() {
    return new Promise((resolve, reject) => {
      if (this._paddleInstance) {
        resolve(null);
        return;
      }
      initializePaddle({
        environment: environment.paddle.environment as Environments,
        token: environment.paddle.token,
        eventCallback: (this.eventCallback as any).bind(this),
      }).then((paddleInstance: Paddle | undefined) => {
        if (paddleInstance) {
          this._paddleInstance = paddleInstance;
          resolve(null);
        } else reject();
      });
    });
  }

  private eventCallback(param: IPaddleEventCallback) {
    switch (param.name) {
      case 'checkout.loaded': {
        this.pixels.sendPixel({
          event: 'checkout',
          step: 'loaded',
        });
        break;
      }
      case 'checkout.customer.created': {
        this.pixels.sendPixel({
          event: 'checkout',
          step: 'customer created',
        });
        break;
      }
      case 'checkout.closed': {
        this.pixels.sendPixel({
          event: 'checkout',
          step: 'closed',
        });
        break;
      }
      case 'checkout.completed': {
        this.pixels.sendPixel({
          event: 'checkout',
          step: 'completed',
        });
        this.sendPurchasePixel(param);
        this.auth.refreshUserDate();
        setTimeout(() => {
          this.auth.refreshUserDate();
          setTimeout(() => {
            this.auth.refreshUserDate();
          }, 3000);
        }, 3000);
        if (this._onCheckout) this._onCheckout();
        break;
      }
    }
  }

  sendPurchasePixel(param: IPaddleEventCallback) {
    this.pixels.sendPixel({
      event: 'purchase',
      transaction_id: param.data.transaction_id,
      // "affiliation": "[Store_Name]",
      value: param.data.totals.total,
      currency: param.data.currency_code,
      tax: param.data.totals.tax,
      items: param.data.items
    });
  }

  async checkout(priceId: string, quantity: number) {
    return new Promise(async (resolve, reject) => {
      await this.init();
      this._onCheckout = resolve;
      this.paddle.Checkout.open({
        items: [{ priceId, quantity }],
        customer: {
          email: this.auth.user.email,
        },
        settings: {
          theme: 'dark',
          allowLogout: false,
          // frameStyle: `body {
          //   background: red !important;
          // }
          // .gfBsSX {
          //   background: red !important;
          // }`
        },
      });
    });
  }

  async prefetch(): Promise<Array<IPaymentPackage>> {
    return this._getPackages();
  };

  private async _getPackages(): Promise<Array<IPaymentPackage>> {
    return new Promise(async (resolve, reject) => {
      if (this._allPrices) {
        resolve(this._allPrices);
        return;
      }
      if (this._getPackagesStock.length > 0) {
        this._getPackagesStock.push(resolve);
        return;
      }
      this._getPackagesStock.push(resolve);
      this._allPrices = (await this.utils.observableToPromise(
        this.rest.package('get')
      )) as Array<IPaymentPackage>;
      this._getPackagesStock.forEach((f) => f(this._allPrices));
      this._getPackagesStock = [];
    });
  }

  getPaddlePricingPeriod(period: PricingPeriod) {
    switch (period) {
      case PricingPeriod.MONTHLY: {
        return 'month';
      }
      case PricingPeriod.YEARLY: {
        return 'year';
      }
    }
  }

  async getAllCyclicalPackages(
    period?: PricingPeriod
  ): Promise<Array<IPaymentPackage>> {
    let res = [] as Array<IPaymentPackage>;
    const prices = this.utils.deepCopyByValue(
      await this._getPackages()
    ) as Array<IPaymentPackage>;
    prices.forEach((p) => {
      let pricesToAdd = [] as Array<IPaymentPackagePrice>;
      p.prices.forEach((price) => {
        if (
          price.billing_cycle?.interval ===
            this.getPaddlePricingPeriod(period) ||
          !period
        ) {
          pricesToAdd.push(price);
        }
      });
      if (pricesToAdd.length) {
        p.prices = pricesToAdd;
        res.push(p);
      }
    });
    for (let i = 0; i < prices.length; i++) {
      prices[i].prices;
    }
    return res.sort((p1, p2) => {
      return (
        parseFloat(p1.prices[0].unit_price.amount) -
        parseFloat(p2.prices[0].unit_price.amount)
      );
    });
  }

  async getAllOnetimePrices(): Promise<Array<IPaymentPackagePrice>> {
    let res = [] as Array<IPaymentPackagePrice>;
    const prices = await this._getPackages();
    prices.forEach((p) => {
      p.prices.forEach((price) => {
        if (!price.billing_cycle) res.push(price);
      });
    });
    for (let i = 0; i < prices.length; i++) {
      prices[i].prices;
    }
    return res;
  }

  public async getPricePreview(
    prices: Array<IPaymentPackagePrice>
  ): Promise<PricePreviewResponse> {
    await this.init();
    const items = [] as Array<PricePreviewItem>;
    prices.forEach((p) => {
      items.push({
        priceId: p.id,
        quantity: 1,
      });
    });
    return this.paddle.PricePreview({
      items,
    });
  }

  public async getPriceDetails(
    packageId: string,
    priceId: string
  ): Promise<IPricingCardDetails> {
    let res: IPricingCardDetails;
    const packages = await this._getPackages();
    const pckg = packages.find((p) => p.id === packageId);
    if (pckg) {
      const price = pckg.prices.find((p) => p.id === priceId);
      if (price) {
        res = this.getCardView(pckg, price);
      }
    }
    return res;
  }

  public async getProductDetails(packageId: string): Promise<IPaymentPackage> {
    const packages = await this._getPackages();
    const pckg = packages.find((p) => p.id === packageId);
    return pckg;
  }

  getCardView(
    pckg: IPaymentPackage,
    price: IPaymentPackagePrice
  ): IPricingCardDetails {
    let cardPrice = parseFloat(price.unit_price.amount) / 100,
      orgPrice;
    if (price.billing_cycle?.interval === 'year') {
      cardPrice *= 1 / 12;
      const monthly = pckg.prices.find(
        (p) => p.billing_cycle?.interval === 'month'
      );
      if (monthly) {
        orgPrice = parseFloat(monthly.unit_price.amount) / 100;
        orgPrice = parseFloat(orgPrice.toFixed(1));
      }
    }
    cardPrice = parseFloat(cardPrice.toFixed(1));
    if (!orgPrice) orgPrice = cardPrice;
    return {
      attributes: Object.values(pckg.custom_data),
      desc: price.description,
      isPopular: pckg.name === 'Starter',
      name: pckg.name,
      price: cardPrice,
      priceId: price.id,
      orgPrice,
      credits: parseInt(price.custom_data['Credits']),
      isCurrent: this.auth.subscription?.price_id === price.id,
      billingCycle: price.billing_cycle,
    };
  }

  async cancelSubscription() {
    await this.utils.observableToPromise(this.rest.subscription('DELETE'));
    this.auth.refreshUserDate();
  }

  changeSubscription(priceId: string, quantity: number) {
    return this.utils.observableToPromise(
      this.rest.subscription('PUT', {
        price_id: priceId,
        quantity,
      })
    );
  }

  async getUserProfile(): Promise<IUserProfile> {
    return {
      user: this.auth.user,
      credits: this.auth.credits,
      subscription: this.auth.subscription,
      subDetails: this.auth.subscription
        ? await this.getPriceDetails(
            this.auth.subscription.product_id,
            this.auth.subscription.price_id
          )
        : null,
    };
  }
}
