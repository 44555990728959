import { Injectable } from '@angular/core';
import {
  IReconstruct,
  IReconstructImage,
  IReconstructJob,
  IReconstructJobUI,
  IReconstructionAction,
  IReconstructionJobQuery,
  ISimilarItem,
  RECONSTRUCT_JOB_STATUS,
  RECONSTRUCTION_ACTION,
  IReconstructTexture,
  GENERATE_TYPE,
  TEXTURE_GENERATE_TYPE,
  IAdvancedOptions,
  IUpscaleImageOptions,


  IReconstructVideoOptions,
  IEditImagesOptions,
  IGenerateImagesFromText,
  ICreateVideoOptions,
  VIDEO_METHOD,
  IUpscaleUIImageOptions,
  SUBSCRIPTION_REASON,
} from './generate';
import { UtilsService } from '../shared/utils.service';
import { RestService } from '../communication/rest.service';
import { PixelsService } from '../shared/pixels.service';
import { GraphqlService } from '../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client';
import { Subject, Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import {
  IPlaygroundNotification,
  IPlaygroundNotificationType,
  PlaygroundNotificationType,
} from '../shared/enums';
import { CustomRequestOptions } from '../communication/custom-request';
import { EnumsService } from '../shared/enums.service';
import { AuthService } from '../auth/auth.service';
import { cardImageSuffix, IMAGE_SAMPLES } from '../shared/constants';
import { RolesHelperService } from '../auth/roles-helper.service';
import { NotificationsService } from '../shared/notifications.service';
import { ResumableUploadService } from '../shared/resumable-upload.service';
import { MatDialog } from '@angular/material/dialog';
import { PricingDialogComponent } from '../pricing/pricing-dialog/pricing-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class GenerateService {
  static NO_SIMILAR = true;
  private _creation: IReconstructJobUI;
  private _videoCreation: IReconstructJobUI;
  private _createdImages: Array<string>;
  private _createdImagesIndex: number;
  private _reconstructedImages: Array<IReconstructImage>;
  private _reconstructedImage: IReconstructImage;
  private _similarItems: Array<ISimilarItem>;
  private _fetchingSimilar: boolean;
  private _text: string;
  private _subs: Array<Subscription>;
  private _isSubscribed: boolean;
  private _similarDictionary: { [id: string]: Array<ISimilarItem> };
  private _allowMultiple: boolean;
  private _state: GENERATE_TYPE;
  private _textureState: TEXTURE_GENERATE_TYPE;
  private _generatingImages: boolean;
  private _generatingImagesJob: IReconstructJobUI;
  private _imageCreationsPromises: { [id: string]: Function };
  private _imageUpscalePromises: { [id: string]: Function };
  private _isUnlimited: boolean;
  // private _advancedOptions: IAdvancedOptions;
  // private _advancedOptionsAction: RECONSTRUCTION_ACTION;
  public onCreationPush: Subject<IPlaygroundNotification>;
  public GENERATE_TYPE = GENERATE_TYPE;
  public TEXTURE_GENERATE_TYPE = TEXTURE_GENERATE_TYPE;
  // public latestUpdated: IReconstructJobUI;
  public counter: number;
  constructor(
    private utils: UtilsService,
    private rest: RestService,
    private pixels: PixelsService,
    private gql: GraphqlService,
    private broadcaster: BroadcasterService,
    private enums: EnumsService,
    private auth: AuthService,
    private roles: RolesHelperService,
    private notifications: NotificationsService,
    private resumableUpload: ResumableUploadService,
    private dialog: MatDialog
  ) {
    this._allowMultiple = true;
    this.counter = 0;
    this._subs = [];
    this._similarDictionary = {};
    this.onCreationPush = new Subject<IPlaygroundNotification>();
    this._imageCreationsPromises = {};
    this._imageUpscalePromises = {};
    this.subscribe();
  }

  get text() {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }

  get creation() {
    return this._creation;
  }

  set creation(value: IReconstructJobUI) {
    if (value) {
      // this._reconstructedImage = null;
      this._videoCreation = null;
    }
    this._creation = value;
  }

  get videoCreation() {
    return this._videoCreation;
  }

  set videoCreation(value: IReconstructJobUI) {
    if (value) {
      this._creation = null;
      this._reconstructedImage = null;
    }
    this._videoCreation = value;
  }

  get createdImages() {
    return this._createdImages;
  }

  set createdImages(value: Array<string>) {
    this._createdImages = value;
  }

  get similarItems() {
    return this._similarItems;
  }

  set similarItems(value: Array<any>) {
    this._similarItems = value;
  }

  get createdImagesIndex() {
    return this._createdImagesIndex;
  }

  set createdImagesIndex(value: number) {
    this._createdImagesIndex = value;
  }

  public get reconstructedImages() {
    return this._reconstructedImages;
  }

  public set reconstructedImages(
    reconstructedImages: Array<IReconstructImage>
  ) {
    this._reconstructedImages = reconstructedImages;
  }

  public get reconstructedImage() {
    return this._reconstructedImage;
  }

  public set reconstructedImage(reconstructedImage: IReconstructImage) {
    if (reconstructedImage) {
      this._videoCreation = null;
      this._creation = null;
    }
    this._reconstructedImage = reconstructedImage;
  }

  public get fetchingSimilar(): boolean {
    return this._fetchingSimilar;
  }

  public get allowMultipleImages() {
    return this._allowMultiple;
  }

  get state() {
    return this._state;
  }

  set state(value: GENERATE_TYPE) {
    this._state = value;
  }

  get textureState() {
    return this._textureState;
  }

  set textureState(value: TEXTURE_GENERATE_TYPE) {
    this._textureState = value;
  }

  get generatingImages() {
    return this._generatingImages;
  }

  set generatingImages(value: boolean) {
    this._generatingImages = value;
  }

  get generatingImagesJob() {
    return this._generatingImagesJob;
  }

  set generatingImagesJob(value: IReconstructJobUI) {
    this._generatingImagesJob = value;
  }

  get isUnlimited() {
    return this._isUnlimited;
  }

  // get advancedOptions() {
  //   return this._advancedOptions;
  // }

  // set advancedOptions(value: IAdvancedOptions) {
  //   this._advancedOptions = value;
  // }

  // get advancedOptionsAction() {
  //   return this._advancedOptionsAction;
  // }

  // set advancedOptionsAction(value: RECONSTRUCTION_ACTION) {
  //   this._advancedOptionsAction = value;
  // }

  async onImageCreationDone(notification: IPlaygroundNotification) {
    const job = (await this.getJobById(notification.job_id)).data.reconstruction_jobs;
    if (job) {
      Object.keys(this._imageCreationsPromises).forEach(async txt => {
        // if (job.reconstruction_jobs_inputs.find(i => i.text_input === txt) || job.preview === txt) {
        if (job.reconstruction_jobs_inputs.find(i => i.text_input === txt)) {
          if (this._imageCreationsPromises[txt])
            this._imageCreationsPromises[txt]((await this.getJobById(notification.job_id)).data.reconstruction_jobs);
          this.afterAction();
        }
      });
    }
  }

  async onImageUpscaleDone(notification: IPlaygroundNotification) {
    const job = (await this.getJobById(notification.job_id)).data.reconstruction_jobs;
    if (job) {
      Object.keys(this._imageUpscalePromises).forEach(async txt => {
        // if (job.reconstruction_jobs_inputs.find(i => i.text_input === txt) || job.preview === txt) {
        if (job.reconstruction_jobs_inputs.find(i => i.image_url === txt)) {
          if (this._imageUpscalePromises[txt])
            this._imageUpscalePromises[txt]((await this.getJobById(notification.job_id)).data.reconstruction_jobs);
          this.afterAction();
        }
      });
    }
  }

  imageTo3D(payload: IReconstruct, advancedOptions: IAdvancedOptions): Promise<IReconstructJob> {
    return new Promise(async (resolve: any, reject: any) => {
      this.pixels.sendPixel({
        event: 'click',
        // button_name: 'generate preview image',
        click_type: 'generate_preview_image',
        low_poly: advancedOptions.low_poly,
        pbr: advancedOptions.pbr,
        poly_count: advancedOptions.poly_count,
        private: advancedOptions.private,
        quad_remesh: advancedOptions.quad_remesh,
        seed: advancedOptions.seed,
        smoothness: advancedOptions.smoothness
      });
      payload.low_poly = advancedOptions.low_poly;
      payload.seed = advancedOptions.seed;
      payload.smoothness = advancedOptions.smoothness;
      payload.quad_remesh = advancedOptions.quad_remesh;
      payload.poly_count = advancedOptions.poly_count;
      payload.public = !advancedOptions.private;
      if (payload.text) payload.text = payload.text.trim();
      try {
        this.creation = (await this.utils.observableToPromise(
          this.rest.reconstruct('POST', payload)
        )) as IReconstructJobUI;
        this.afterAction();
        this.creation._delayEnter = 1;
        // this.latestUpdated = res;
        this.createdImages = null;
        this.createdImagesIndex = 0;
        this.afterAction();
        resolve(this.creation);
      }
      catch (e) {
        this.pixels.sendPixel({
          event: 'failure',
          click_type: 'generate_preview_image'
        });
        reject(e);
      }
    });
  }

  async upscaleImage(options: IUpscaleUIImageOptions, onJobCompleted?: Function): Promise<IReconstructJobUI> {
    return new Promise(async (resolve: any, reject: any) => {
      this._imageUpscalePromises[options.src] = onJobCompleted;
      const res = this.utils.observableToPromise(
        this.rest.reconstructImage('POST', {
          images: [options.src],
          action_id: RECONSTRUCTION_ACTION.UPSCALE_IMAGE,
          prompt: 'high-quality, noise-free edges, high quality, 4k, hd, 8k',
          samples: 1,
          public: !options.private
        } as IUpscaleImageOptions)
      );
      this.afterAction();
      resolve(res);
    });
  }

  async reconstructImage(options: IGenerateImagesFromText, onJobCompleted?: Function): Promise<IReconstructJobUI> {
    return new Promise(async (resolve: any, reject: any) => {
      this._imageCreationsPromises[options.text] = onJobCompleted;
      const res = this.utils.observableToPromise(
        this.rest.reconstructImage('POST', { prompt: options.text.trim(), public: options.public, samples: options.samples })
      );
      this.afterAction();
      resolve(res);
    });
  }

  public getJobById(
    id: number
  ): Promise<ApolloQueryResult<IReconstructionJobQuery>> {
    return this.utils.observableToPromise(this.gql.reconstructionJob(id));
  }

  public getSimilarProducts(imageURL: string): Promise<Array<ISimilarItem>> {
    return new Promise(async (resolve: any, reject: any) => {
      imageURL = this.utils.setUrlParam(imageURL, 'w', null);
      imageURL = this.utils.setUrlParam(imageURL, 'h', null);
      if (GenerateService.NO_SIMILAR && !this.roles.isRoleLogedin('Super User')) {
        resolve({});
        return;
      }
      if (this._similarDictionary[imageURL]) {
        resolve(this._similarDictionary[imageURL]);
        return;
      }
      this._fetchingSimilar = true;
      const o = new CustomRequestOptions();
      o.showLoading = false;
      this._similarDictionary[imageURL] = await this.utils.observableToPromise(
        this.rest.similar(
          'POST',
          {
            image_url: imageURL,
          },
          '',
          o
        )
      );
      this._fetchingSimilar = false;
      resolve(this._similarDictionary[imageURL]);
      const classification = await this.getImageClassification(imageURL, false);
      this.pixels.sendPixel({
        event: 'similar',
        free_similar_count: this._similarDictionary[imageURL].length,
        premium_similar_count: this._similarDictionary[imageURL].length,
        classification,
        highest_score: this._similarDictionary[imageURL][0]?.score
      });
    });
  }

  public getImageClassification(
    imageURL: string,
    showLoading: boolean
  ): Promise<string> {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const o = new CustomRequestOptions();
        o.showLoading = showLoading;
        const res = await this.utils.observableToPromise(
          this.rest.classify(
            'POST',
            {
              image_url: imageURL,
            },
            '',
            o
          )
        );
        resolve(res.classification || res.top_category);
      } catch (e) {
        // reject(e);
        console.error(e);
        resolve('');
      }
    });
  }

  private subscribe() {
    if (this._isSubscribed) return;
    this._isSubscribed = true;
    this._subs.push(this.notifications.onImageCreationDone.subscribe(this.onImageCreationDone.bind(this)));
    this._subs.push(this.notifications.onUpscaleImageDone.subscribe(this.onImageUpscaleDone.bind(this)));
    this._subs.push(
      this.broadcaster
        .on('onDocumentFocus')
        .subscribe(this.onDocumentFocus.bind(this))
    );
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe(async (d: any) => {
        const data = d as IPlaygroundNotification;
        if (data.status === RECONSTRUCT_JOB_STATUS.Failed)
          this.auth.refreshUserDate();
        if (
          data.notifications_types?.find(
            (t: IPlaygroundNotificationType) =>
              t.id === PlaygroundNotificationType.JOB_STATUS_CHANGE ||
              t.id ===
              PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED
          )
        ) {
          this.onCreationPush.next(data);
          if (data.job_id) {
            let c;
            if (
              data.status === RECONSTRUCT_JOB_STATUS.Queued ||
              data.job_id === this.creation?.id
            )
              c = this.utils.deepCopyByValue(
                (await this.getJobById(data.job_id)).data.reconstruction_jobs
              );
            if (data.status === RECONSTRUCT_JOB_STATUS.Queued) {
              this.broadcaster.broadcast('onLatestUpdated', c);
            }
            if (data.job_id === this.creation?.id) {
              Object.assign(this.creation, c);
              this.counter++;
            }
          }
        }
      })
    );
    this._subs.push(this.broadcaster.on('onLogin').subscribe(this._refreshUnlimited.bind(this)));
    this._subs.push(this.broadcaster.on('onLogout').subscribe(this._refreshUnlimited.bind(this)));
    this._subs.push(this.broadcaster.on('userRefreshed').subscribe(this._refreshUnlimited.bind(this)));
    this._subs.push(this.broadcaster.on('rolesRefreshed').subscribe(this._refreshUnlimited.bind(this)));
    this._subs.push(this.roles.onRolesFetched.subscribe(this._refreshUnlimited.bind(this)));
    this._refreshUnlimited();
  }

  private async _refreshUnlimited() {
    this._isUnlimited = await this.roles.doesUserHasPermission('Unlimited GenAI Credits');
  }

  async onDocumentFocus() {
    if (this.creation) {
      const creation = (await this.getJobById(this.creation.id)).data
        .reconstruction_jobs;
      Object.assign(this.creation, creation);
      this.counter++;
    }
  }

  async updateJob(job: IReconstructJobUI): Promise<IReconstructJobUI> {
    const j = this.utils.deepCopyByValue(
      (await this.getJobById(job.id)).data.reconstruction_jobs
    ) as IReconstructJobUI;
    j._isCurrent = job._isCurrent;
    j._delayEnter = job._delayEnter;
    Object.assign(job, j);
    return job;
  }

  async generateImagesFromText(options: IGenerateImagesFromText) {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'generate_image_from_text',
      private: !options.public
    });
    const gif = '/assets/images/loading.gif';
    this.createdImages = [gif, gif, gif, gif];
    this.creation = null;
    this.generatingImages = true;
    this.generatingImagesJob = await this.reconstructImage(options, async (res: IReconstructJob) => {
      const images = res.reconstruction_images.map((i) => i.url);
      await this.utils.preloadImages(images.map(i => i + cardImageSuffix));
      this.creation = null;
      this.createdImagesIndex = 0;
      this.createdImages = images;
      this.generatingImages = false;
      this.generatingImagesJob = null;
      this.similarItems = await this.getSimilarProducts(
        this.createdImages[this.createdImagesIndex]
      );
    }) as IReconstructJobUI;
  }

  async editImages(options: IEditImagesOptions) {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'edit_image'
    });
    const images = [];
    for (let i = 0; i < options.images.length; i++) {
      if (this.utils.getFileExtension(this.utils.getFileFromPath(options.images[i])) === 'webp')
        images.push(await this.resumableUpload.base64ToURL(await this.utils.convertImageFormat(options.images[i], 'png'), 'img', 'image/png'));
      else
        images.push(options.images[i]);
    }
    const payload = {
      action_id: RECONSTRUCTION_ACTION.GENERATE_IMAGE,
      prompt: options.prompt,
      images,
      width: 1024,
      height: 1024,
      mode: 'subject',
      samples: IMAGE_SAMPLES,
      public: options.public
    };
    this.generatingImagesJob = (await this.utils.observableToPromise(
      this.rest.reconstructImage('POST', payload)
    )) as IReconstructJobUI;
    this.afterAction();
    this.broadcaster.broadcast('editImageSent', this.generatingImagesJob);
    this.broadcaster.broadcast('onGenerating', this.generatingImagesJob);
    return this.generatingImagesJob;
  }

  async createVideo(options: ICreateVideoOptions) {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'create_video'
    });
    const images = [];
    for (let i = 0; i < options.images.length; i++) {
      if (this.utils.getFileExtension(this.utils.getFileFromPath(options.images[i])) === 'webp')
        // images.push(await this.utils.convertImageFormat(options.images[i], 'png'));
        images.push(await this.resumableUpload.base64ToURL(await this.utils.convertImageFormat(options.images[i], 'png'), 'img', 'image/png'));
      else
        images.push(options.images[i]);
    }
    const payload = {
      action_id: RECONSTRUCTION_ACTION.GENERATE_VIDEO,
      prompt: options.prompt,
      images,
      public: options.public
    } as any;
    if (options.method === VIDEO_METHOD.SVC) {
      payload.method = options.method;
      payload.resolution = options.resolution;
      payload.trajectory_type = options.trajectory_type;
    }
    this.videoCreation = (await this.utils.observableToPromise(
      this.rest.reconstructVideo('POST', payload)
    )) as IReconstructJobUI;
    this.afterAction();
    this.broadcaster.broadcast('createVideoSent', this.videoCreation);
    this.broadcaster.broadcast('onGenerating', this.videoCreation);
    return this.videoCreation;
  }

  async getAction(type: RECONSTRUCTION_ACTION): Promise<IReconstructionAction> {
    return this.enums.getActionByType(
      await this.auth.getReconstructionActions(),
      type
    );
  }

  async generateTexture(
    input: IReconstructTexture,
    advancedOptions: IAdvancedOptions
  ): Promise<IReconstructJob> {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_texture',
      sub_click_type: 'texture',
    });
    if (input.text) input.text = input.text.trim();
    const payload = {
      action_id: advancedOptions.pbr ? RECONSTRUCTION_ACTION.RE_TEXTURE_PBR : RECONSTRUCTION_ACTION.RE_TEXTURE,
      text: input.text,
      images: input.images,
      source_glb_url: input.modelURL,
      smoothness: advancedOptions.smoothness,
      low_poly: advancedOptions.low_poly,
    } as IReconstruct;
    this.creation = (await this.utils.observableToPromise(
      this.rest.reconstruct('POST', payload)
    )) as IReconstructJobUI;
    // this.utils.observableToPromise(
    //   this.rest.reconstructTexture('POST', { prompt: text, glb: fileURL })
    // );
    this.afterAction();
    this.broadcaster.broadcast('generateTextureSent', this.creation);
    this.broadcaster.broadcast('onGenerating', this.creation);
    return this.creation;
  }

  async generateVideo(
    input: IReconstructVideoOptions
  ): Promise<IReconstructJob> {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_video'
    });
    if (input.text) input.text = input.text.trim();
    const payload = {
      action_id: RECONSTRUCTION_ACTION.GENERATE_VIDEO,
      text: input.text,
      images: input.images
    } as IReconstruct;
    this.videoCreation = (await this.utils.observableToPromise(
      this.rest.reconstruct('POST', payload)
    )) as IReconstructJobUI;
    this.afterAction();
    this.broadcaster.broadcast('generateVideoSent', this.videoCreation);
    this.broadcaster.broadcast('onGenerating', this.videoCreation);
    return this.videoCreation;
  }

  afterAction() {
    this.auth.refreshUserDate();
  }

  async deleteJob(job: IReconstructJobUI) {
    if (this.creation?.id === job.id) this.creation = null;
    await this.utils.observableToPromise(
      this.rest.reconstructJobs('DELETE', null, `/${job.id}`)
    );
  }

  hasPreview() {
    return !!(
      this.creation ||
      (this.createdImages && this.createdImages[this.createdImagesIndex]) ||
      this.videoCreation || this.reconstructedImage
    );
  }

  checkCreditsAndPrompt(require: number, prompt: boolean): boolean {
    if (this.auth.credits < require) {
      if (prompt)
        this.dialog.open(PricingDialogComponent, {
          data: SUBSCRIPTION_REASON.INSUFFICIENT_CREDITS
        });
      return false;
    }
    return true;
  }

  validateSubscription(action: IReconstructionAction): boolean {
    if (!action.require_subscription) return true;
    if (this.auth.subscription) return true;
    if (this.isUnlimited) return true;
    return false;
  }

  canRequestPrivate(): boolean {
    return this.isUnlimited || !!this.auth.subscription;
  }

  resetAll() {
    this.creation = null;
    this.createdImages = null;
    this.videoCreation = null;
    this.reconstructedImage = null;
    this.generatingImagesJob = null;
  }

  // private unsubscribe() {
  //   if (!this._isSubscribed) return;
  //   this._isSubscribed = false;
  //   this._subs.forEach((s) => s.unsubscribe());
  //   this._subs = [];
  // }
}
