@if (ready) {
<div class="ttl pos-rel">
    <h2 class="ttl-sm">Upgrade your plan</h2>
    <button type="button" mat-raised-button class="btn btn-ghost close pos-abs" (click)="close()">
        <img class="btn-icon" src="/assets/images/close.svg" alt="close" />
    </button>
</div>
<div class="disp-flex row mobile-col context">
    <div class="text-center align-content-center">
        @switch (context) {
        @case (SUBSCRIPTION_REASON.INSUFFICIENT_CREDITS) {
        <div><img class="va-text-top marg-r" src="/assets/images/coin.svg" alt=""> Credit amount is insufficient. Please choose a subscription in order to proceed.</div>
        }
        @case (SUBSCRIPTION_REASON.REFINE) {
        Active subscruption is mandatory in order to <b>Refine</b> your model
        }
        @case (SUBSCRIPTION_REASON.REFINE_PBR) {
        Active subscruption is mandatory in order to create a <b>PBR</b> model
        }
        @case (SUBSCRIPTION_REASON.UPSCALE_IMAGE) {
        Active subscruption is mandatory in order to <b>Upscale</b> an image
        }
        @case (SUBSCRIPTION_REASON.PRIVATE) {
        Unlock <b>private</b> licensing with paid subscription
        }
        @case (SUBSCRIPTION_REASON.GENERATE_VIDEO) {
        Unlock <b>video creation</b> licensing with paid subscription
        }
        @case (SUBSCRIPTION_REASON.GENERATE_IMAGE) {
        Unlock <b>image creation</b> licensing with paid subscription
        }
        }
    </div>
    <div class="buttons disp-inline-flex align-end-flex max-w-100">
        <div class="button-wrap">
            <button (click)="state = MONTHLY" [ngClass]="{ active: state === MONTHLY }" type="button" mat-raised-button
                class="btn btn-g">
                Monthly
            </button>
        </div>
        <div class="button-wrap">
            <button (click)="state = YEARLY" [ngClass]="{ active: state === YEARLY }" type="button" mat-raised-button
                class="btn btn-g">
                Yearly (20% off)
            </button>
        </div>
    </div>
</div>
<div class="pricing-wrap">
    <app-pricing-view [pricing-period]="state"></app-pricing-view>
</div>
}