import { Component, Input } from '@angular/core';
import { PixelsService } from '../pixels.service';
import { UtilsService } from '../utils.service';
import { ImageDialog } from '../../product/product';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { SrcSuffixPipe } from '../src-suffix.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { cardImageSuffix } from '../constants';

@Component({
    selector: 'app-image-card',
    imports: [MatIconModule, SrcSuffixPipe, MatButtonModule, CommonModule],
    templateUrl: './image-card.component.html',
    styleUrl: './image-card.component.scss'
})
export class ImageCardComponent {
  public loading: boolean;
  public cardImageSuffix = cardImageSuffix;
  @Input() src: string;
  @Input('enable-view') enableView: boolean;
  constructor(
    private dialog: MatDialog,
    private pixels: PixelsService,
    private utils: UtilsService
  ) {

  }

  async view(src: string) {
    this.loading = true;
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'enlarge_preview_image',
    });
    await this.utils.preloadImage(src);
    this.loading = false;
    const m = {
      title: '',
      url: src,
    } as ImageDialog;
    this.dialog.open(ImageDialogComponent, {
      data: m,
    });
  }
}
