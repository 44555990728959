<div class="img pos-rel poiner" [ngClass]="{'disabled': !enableView}" >
  @if (enableView) {
  <button
    type="button"
    class="link-image pos-abs"
    mat-icon-button
    (click)="view(src)"
  >
    <mat-icon>visibility</mat-icon>
  </button>
  }
  <img [src]="src | srcSuffix : cardImageSuffix : true" alt="option ${$index}" />
</div>
